import { motion } from "framer-motion";
import * as React from "react";
import { SectionAnimation } from "../../../../utils/types";
import Greek from "./Greek";
import { rollupContainerStyle } from "./index.css";

const numberOfRollups = 10;

const DecorationSection: SectionAnimation = ({ y2 }) => {
    return (
        <motion.div className={rollupContainerStyle} style={{ x: y2 }}>
            {new Array(numberOfRollups).fill(<Greek />).map((_, index) => (
                <Greek key={index} />
            ))}
        </motion.div>
    );
};

export default DecorationSection;
