import { motion } from "framer-motion";
import * as React from "react";
import { SectionAnimation } from "../../../../utils/types";
import Box from "./Box";
import { animationContainerStyle, boxColumnVariants } from "./index.css";

const numberOfBoxes = 8;

const BoxSection: SectionAnimation = ({ y, y2 }) => (
    <motion.div className={animationContainerStyle}>
        <motion.div
            className={boxColumnVariants.down}
            style={{
                y,
            }}
        >
            {new Array(numberOfBoxes).fill(<Box />).map((_, index) => (
                <Box key={index} />
            ))}
        </motion.div>
        <motion.div
            className={boxColumnVariants.up}
            style={{
                y: y2,
            }}
        >
            {new Array(numberOfBoxes).fill(<Box />).map((_, index) => (
                <Box key={index} />
            ))}
        </motion.div>
    </motion.div>
);

export default BoxSection;
