import { motion } from "framer-motion";
import { Link } from "gatsby";
import * as React from "react";
import useColor from "../../context/ColorContext";
import useParallax from "../../hooks/useParallax";
import { highlight } from "../Highlight/style.css";
import { heroButtonStyle, heroPanelStyle } from "./style.css";

const HeroPanel = () => {
    const { color } = useColor();
    const [ref, y] = useParallax(0.2);

    return (
        <section className={heroPanelStyle} ref={ref}>
            <motion.h1 style={{ y }}>
                <div>
                    Nadajemy
                    <span className={highlight({ color })}> kształt</span>
                    <br />
                    Twoim wizjom
                </div>
                <Link to="/about" tabIndex={-1}>
                    <button className={heroButtonStyle}>o firmie</button>
                </Link>
            </motion.h1>
        </section>
    );
};

export default HeroPanel;
