import { motion } from "framer-motion";
import * as React from "react";
import DisplaySection from "./DisplaySection";
import AssemblySection from "./DisplaySection/AssemblySection";
import BoxSection from "./DisplaySection/VisualSection";
import DecorationSection from "./DisplaySection/DecorationSection";
import PrintSection from "./DisplaySection/PrintSection";
import { headingStyle, sectionContainerStyle } from "./index.css";
import useColor from "../../context/ColorContext";
import { highlight } from "../Highlight/style.css";

const SectionsContainer = () => {
    const { color } = useColor();
    return (
        <motion.div className={sectionContainerStyle}>
            <h2 className={headingStyle}>
                Co
                <span className={highlight({ color })}> robimy</span>?
            </h2>
            <DisplaySection
                heading="Druk cyfrowy i offsetowy"
                text="Wydruki w każdej wielkości już od jednej sztuki na dowolnym dostępnym podłożu."
                comp={PrintSection}
                to={"print"}
                color={"blue"}
            ></DisplaySection>
            <DisplaySection
                heading="Reklama wizualna"
                text="Szyldy, litery przestrzenne i kasetony oraz inne kreatywne rozwiązania wizualne."
                comp={BoxSection}
                to={"visual"}
                color={"red"}
            />
            <DisplaySection
                heading="Dekoracja wnętrz"
                text="Indywidualne elementy dekoracyjne stworzone do wnętrz prywatnych i komercyjnych."
                comp={DecorationSection}
                to={"decoration"}
                color={"green"}
            />
            <DisplaySection
                heading="Montaż reklam"
                text="Montaż i serwis wszystkich oferowanych produktów a także powierzonych przez Klienta."
                comp={AssemblySection}
                to={"assembly"}
                color={"yellow"}
            />
        </motion.div>
    );
};

export default SectionsContainer;
