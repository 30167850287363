import * as React from "react";
import { useWindowSize } from "usehooks-ts";
import { SVGBoxStyle } from "./index.css";

const Box = () => {
    const { width } = useWindowSize();

    if (width <= 768) {
        return (
            <svg
                width="123"
                height="123"
                viewBox="0 0 123 123"
                fill="none"
                className={SVGBoxStyle}
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    y="23.1113"
                    width="108.75"
                    height="99.8151"
                    fill="#D41C54"
                />
                <path
                    d="M108.75 23.1118L122.344 0.930664V100.746L108.75 122.927V23.1118Z"
                    fill="#B9134F"
                />
                <path
                    d="M13.5938 0.930664H122.344L108.75 23.1118H0L13.5938 0.930664Z"
                    fill="#D01659"
                />
            </svg>
        );
    }

    return (
        <svg
            width="270"
            height="165"
            viewBox="0 0 270 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={SVGBoxStyle}
        >
            <rect y="30" width="240" height="135" fill="#D41C54" />
            <path d="M240 30L270 0V135L240 165V30Z" fill="#B9134F" />
            <path d="M30 0H270L240 30H0L30 0Z" fill="#D01659" />
        </svg>
    );
};

export default Box;
