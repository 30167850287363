import { MotionValue, useTransform, useViewportScroll } from "framer-motion";
import * as React from "react";

type UseParallaxType = (
    speed?: number
) => [React.RefObject<HTMLDivElement>, MotionValue<number>];

const useParallax: UseParallaxType = (speed = 1) => {
    const [elementTop, setElementTop] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);
    const { scrollY } = useViewportScroll();

    const value = useTransform(
        scrollY,
        [elementTop, elementTop + 1],
        [0, -1 * speed],
        {
            clamp: false,
        }
    );

    React.useLayoutEffect(() => {
        const element = ref.current;
        setElementTop(element?.offsetTop ?? 0);
    }, [ref]);

    return [ref, value];
};

export default useParallax;
