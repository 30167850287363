import { motion, useTransform } from "framer-motion";
import * as React from "react";
import useParallax from "../../../hooks/useParallax";
import {
    contentStyle,
    sectionVariants,
    textContainerVariants,
    textVariants,
    titleVariants,
} from "./index.css";
import { SectionAnimation } from "../../../utils/types";
import { Link } from "gatsby";
import { button } from "../../Button/style.css";

type DisplaySectionProps = {
    heading: string;
    text: string;
    comp: SectionAnimation;
    to: string;
    color: "blue" | "green" | "red" | "yellow";
};

const DisplaySection = ({
    heading,
    text,
    comp,
    to,
    color,
}: DisplaySectionProps) => {
    const [ref, y] = useParallax(0.4);
    const y2 = useTransform(y, (latest) => latest * -1);

    return (
        <motion.section className={sectionVariants[color]} ref={ref}>
            <div className={contentStyle}>
                <div className={textContainerVariants[color]}>
                    <h2 className={titleVariants[color]}>{heading}</h2>
                    <p className={textVariants[color]}>{text}</p>
                    <Link to={to} style={{ display: "grid" }} tabIndex={-1}>
                        <button
                            className={button({
                                color,
                            })}
                        >
                            zobacz więcej
                        </button>
                    </Link>
                </div>
            </div>
            {comp({ y, y2 })}
        </motion.section>
    );
};

export default DisplaySection;
