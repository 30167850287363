import { motion, useAnimation } from "framer-motion";
import * as React from "react";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "usehooks-ts";
import AssemblyBox from "./AssemblyBox";
import { animationContainerStyle } from "./index.css";

const AssemblySection = () => {
    const animation = useAnimation();
    const [ref, inView] = useInView({ threshold: 0.5 });
    const { width } = useWindowSize();
    const [displayContent, setDisplayContent] = React.useState(false);

    React.useEffect(() => {
        /* 
        Prevents the animation from playing when the page is loading
        This was causing the animation to play twice, first when the page loads,
        mobile animation plays, then when the page loads again, desktop animation plays
        */
        setDisplayContent(true);
    }, []);

    React.useEffect(() => {
        animation.start(inView ? "animate" : "default");
    }, [animation, inView]);

    if (!displayContent) return <div />;

    return (
        <motion.div
            className={animationContainerStyle}
            ref={ref}
            exit={{ opacity: 0 }}
        >
            <AssemblyBox
                key={width <= 768 ? "mobile" : "desktop"}
                mobile={width <= 768}
                animation={animation}
            />
        </motion.div>
    );
};

export default AssemblySection;
