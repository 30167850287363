import * as React from "react";
import { greekStyle } from "./style.css";

const Greek = () => {
    return (
        <>
            <svg
                width="240"
                height="200"
                viewBox="0 0 120 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={greekStyle}
            >
                <rect x="20" y="40" width="20" height="20" />
                <rect y="80" width="20" height="20" />
                <rect x="100" y="80" width="20" height="20" />
                <rect x="100" y="60" width="20" height="20" />
                <rect x="100" y="40" width="20" height="20" />
                <rect x="100" y="20" width="20" height="20" />
                <rect x="100" width="20" height="20" />
                <rect x="80" width="20" height="20" />
                <rect x="60" width="20" height="20" />
                <rect x="40" width="20" height="20" />
                <rect x="20" width="20" height="20" />
                <rect x="20" y="20" width="20" height="20" />
                <rect x="20" y="80" width="20" height="20" />
                <rect x="40" y="80" width="20" height="20" />
                <rect x="60" y="80" width="20" height="20" />
                <rect x="60" y="60" width="20" height="20" />
                <rect x="60" y="40" width="20" height="20" />
                <rect x="40" y="40" width="20" height="20" />
            </svg>
        </>
    );
};

export default Greek;
