import { motion } from "framer-motion";
import * as React from "react";
import { SectionAnimation } from "../../../../utils/types";
import { paperColumnStyle } from "./index.css";
import Paper from "./Paper";

const PrintSection: SectionAnimation = ({ y, y2 }) => {
    return (
        <>
            <motion.div
                style={{
                    position: "absolute",
                    display: "flex",
                    top: -200,
                    right: -200,
                    justifyContent: "flex-end",
                    gap: "5rem",
                }}
                exit={{ opacity: 0 }}
            >
                <motion.div style={{ y: y, x: y }}>
                    <div className={paperColumnStyle}>
                        {new Array(6).fill(<Paper />).map((_, index) => (
                            <Paper key={index} />
                        ))}
                    </div>
                </motion.div>
                <motion.div style={{ y: y2, x: y2 }}>
                    <div className={paperColumnStyle}>
                        {new Array(6).fill(<Paper />).map((_, index) => (
                            <Paper key={index} />
                        ))}
                    </div>
                </motion.div>
                <motion.div style={{ y: y, x: y }}>
                    <div className={paperColumnStyle}>
                        {new Array(6).fill(<Paper />).map((_, index) => (
                            <Paper key={index} />
                        ))}
                    </div>
                </motion.div>
            </motion.div>
        </>
    );
};

export default PrintSection;
