import * as React from "react";
import Layout from "../components/Layout";
import HeroPanel from "../components/HeroPanel";
import SectionsContainer from "../components/SectionsContainer";
import useColor from "../context/ColorContext";

const Home = () => {
    const { setColor } = useColor();
    React.useEffect(() => {
        setColor?.("blue");
    }, []);
    return (
        <Layout>
            <HeroPanel />
            <SectionsContainer />
        </Layout>
    );
};

export default Home;
