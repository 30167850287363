import { AnimationControls, motion } from "framer-motion";
import * as React from "react";
import {
    BoxPart1,
    BoxPart2,
    BoxPart3,
    BoxPart4,
    SVGBoxStyle,
} from "./index.css";

const AssemblyBox = ({
    animation,
    mobile,
}: {
    animation: AnimationControls;
    mobile: boolean;
}) => {
    if (mobile) {
        return (
            <svg
                width="228"
                height="244"
                viewBox="0 0 114 122"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={SVGBoxStyle}
            >
                {/* BACK */}
                <rect x="14" width="100" height="100" className={BoxPart1} />

                {/* LEFT */}
                <motion.path
                    animate={animation}
                    variants={variants.fromTop}
                    initial="default"
                    transition={{ duration: 1 }}
                    d="M0 22L14 0V100L0 122V22Z"
                    className={BoxPart4}
                />

                {/* TOP */}
                <motion.path
                    animate={animation}
                    variants={variants.fromRight}
                    initial="default"
                    transition={{ duration: 1 }}
                    d="M14 0H114L100 22H0L14 0Z"
                    className={BoxPart3}
                />
                {/* BOTTOM */}
                <motion.path
                    animate={animation}
                    variants={variants.fromLeft}
                    initial="default"
                    transition={{ duration: 1 }}
                    d="M14 100H114L100 122H0L14 100Z"
                    className={BoxPart3}
                />
                {/* FRONT */}
                <motion.rect
                    animate={animation}
                    variants={variants.cover}
                    initial="default"
                    transition={{ duration: 1, delay: 0.5 }}
                    y="22"
                    width="100"
                    height="100"
                    className={BoxPart2}
                />
                {/* RIGHT */}
                <motion.path
                    animate={animation}
                    variants={variants.fromBottom}
                    initial="default"
                    transition={{ duration: 1 }}
                    d="M100 22L114 0V100L100 122V22Z"
                    className={BoxPart4}
                />
            </svg>
        );
    }

    // DESKTOP
    return (
        <>
            <svg
                width="378"
                height="231"
                viewBox="0 0 378 231"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={SVGBoxStyle}
            >
                {/* BACK */}
                <rect x="42" width="336" height="189" className={BoxPart1} />
                {/* BOTTOM ?*/}
                <motion.path
                    animate={animation}
                    variants={variants.fromLeft}
                    initial="default"
                    transition={{ duration: 1 }}
                    d="M42 189H378L336 231H0L42 189Z"
                    className={BoxPart3}
                />
                {/* LEFT ?*/}
                <motion.path
                    d="M0 42L42 0V189L0 231V42Z"
                    className={BoxPart4}
                    animate={animation}
                    variants={variants.fromTop}
                    initial="default"
                    transition={{ duration: 1 }}
                />
                {/* COVER */}
                <motion.rect
                    y="42"
                    width="336"
                    height="189"
                    className={BoxPart2}
                    animate={animation}
                    variants={variants.cover}
                    initial="default"
                    transition={{ duration: 1, delay: 0.5 }}
                />
                {/* RIGHT */}
                <motion.path
                    animate={animation}
                    variants={variants.fromBottom}
                    initial="default"
                    transition={{ duration: 1 }}
                    d="M336 42L378 0V189L336 231V42Z"
                    className={BoxPart4}
                />
                {/* TOP */}
                <motion.path
                    animate={animation}
                    variants={variants.fromRight}
                    initial="default"
                    transition={{ duration: 1 }}
                    d="M42 0H378L336 42H0L42 0Z"
                    className={BoxPart3}
                />
            </svg>
        </>
    );
};

const variants = {
    fromTop: {
        default: {
            y: -300,
        },
        animate: {
            y: 0,
        },
    },
    fromBottom: {
        default: {
            y: 300,
        },
        animate: {
            y: 0,
        },
    },

    fromLeft: {
        default: {
            x: -339,
        },
        animate: {
            x: 0,
        },
    },
    fromRight: {
        default: {
            x: 300,
        },
        animate: {
            x: 0,
        },
    },
    cover: {
        default: {
            x: -678,
        },
        animate: {
            x: 0,
        },
    },
};

export default AssemblyBox;
